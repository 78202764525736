import React from "react"
import { Fade } from "react-awesome-reveal"
import styled from "styled-components"
import shopImage1 from "../assets/images/06_SHOP_carousel/shop_1.jpg"
import shopImage2 from "../assets/images/06_SHOP_carousel/shop_2.jpg"
import shopImage3 from "../assets/images/06_SHOP_carousel/shop_3.jpg"
import shopImage4 from "../assets/images/06_SHOP_carousel/shop_4.jpg"
import shopImage5 from "../assets/images/06_SHOP_carousel/shop_5.jpg"
import shopImage6 from "../assets/images/06_SHOP_carousel/shop_6.jpg"
import SEO from "../components/SEO"

import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import "../styles/sliderStyler.css"

const PageStyles = styled.div`
  /* scroll-snap-type: y mandatory;
  overflow-y: scroll; */
  height: calc(100vh - 150px);
`

const ImageStyles = styled.div`
  /* scroll-snap-align: start; */
  background-size: cover;
  height: calc(100vh - 150px);
`

const BodyStyles = styled.div`
  /* scroll-snap-align: start; */
  display: flex;
  min-height: calc(100vh - 150px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 130px;

  @media (max-width: 900px) {
    padding-left: 90px;
    padding-right: 40px;
    padding-bottom: 120px;
    padding-top: 45px;
    min-height: 0;
  }

  h1 {
    margin-left: -65px;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 20px;
  }

  article {
    columns: 1;
    column-width: 330px;
    column-gap: 30px;
  }
`

const ShopPage = () => {
  const [pause, setPause] = React.useState(false)
  const timer = React.useRef()
  const [sliderRef, slider] = useKeenSlider({
    loop: true,
    duration: 1000,
    dragStart: () => {
      setPause(true)
    },
    dragEnd: () => {
      setPause(false)
    },
  })

  React.useEffect(() => {
    sliderRef.current.addEventListener("mouseover", () => {
      setPause(true)
    })
    sliderRef.current.addEventListener("mouseout", () => {
      setPause(false)
    })
  }, [sliderRef])

  React.useEffect(() => {
    timer.current = setInterval(() => {
      if (!pause && slider) {
        slider.next()
      }
    }, 2000)
    return () => {
      clearInterval(timer.current)
    }
  }, [pause, slider])

  return (
    <>
      <SEO title="Shop" image={shopImage1} />
      <Fade triggerOnce>
        <PageStyles>
          <div ref={sliderRef} className="keen-slider">
            <div
              className="keen-slider__slide number-slide1"
              style={{
                backgroundImage: `url(${shopImage1})`,
              }}
            ></div>
            <div
              className="keen-slider__slide number-slide2"
              style={{ backgroundImage: `url(${shopImage2})` }}
            ></div>
            <div
              className="keen-slider__slide number-slide3"
              style={{ backgroundImage: `url(${shopImage3})` }}
            ></div>
            <div
              className="keen-slider__slide number-slide3"
              style={{ backgroundImage: `url(${shopImage4})` }}
            ></div>
            <div
              className="keen-slider__slide number-slide4"
              style={{ backgroundImage: `url(${shopImage5})` }}
            ></div>
            <div
              className="keen-slider__slide number-slide5"
              style={{ backgroundImage: `url(${shopImage6})` }}
            ></div>
          </div>
          <BodyStyles>
            <div>
              <h1>SHOP</h1>

              <article>
                <p>Opening hours:</p>
                <p>8am to 10pm everyday</p>
                <p>
                  We currently do not sell online, all verse products are sold
                  exclusively from our store at verse collective, Sri Lanka.
                </p>
                <p>We hope to see you soon!</p>
              </article>
            </div>
          </BodyStyles>
        </PageStyles>
      </Fade>
    </>
  )
}

export default ShopPage
